import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer center-content-mobile',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer 
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top center-content text-xxs">
              <Logo />
              {/* <FooterSocial /> */}
            </div>
            <div className="footer-bottom center-content text-xxs invert-order-desktop">
              {/* <FooterNav /> */}
              <div className="footer-copyright">#24, Second Floor, <br />J. N. Road BEML Layout,3rd Stage,<br />Rajarajeshwari Nagar,<br />Bangalore  KA560098 IN</div>
              {/* <div className="footer-copyright">#24, Second Floor, J. N. Road BEML Layout,3rd Stage, Rajarajeshwari Nagar, Bangalore  KA560098 IN</div>  */}
              <div className="footer-copyright" >© 2021 Unifrontier Technologies pvt ltd, all rights reserved</div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;